import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import * as styles from "../RichTextModel/RichTextModel-tw-styles"

const TextContent = props => {
    const { title, shortDescription, variant } = props
    const style = variant ? styles[variant] : ["COOKIES_INFO"]
    return (
        <div className={style.richTextWrapper}>
            {title && <div className={style.title}>{title}</div>}     
            <Typography content={shortDescription} allow_br_tag={true}  data-testid="typography" />
        </div>
    ) 
}

TextContent.propTypes = {
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  variant: PropTypes.string,
}

export default TextContent
