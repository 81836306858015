const query = id => `
{
  textContent (id: "${id}") {
      title
      shortDescription
      variant
  }
}
`

module.exports = {query}